import { baseController } from '../../apiServices'

export const getUrlObject = async (params) => {
	const API = baseController()
	const res = await API.getUrlObject(params)
	if (!res) {
		return false
	} else {
		return res.data
	}
}

export const openFile = async (files) => {
  const res = await getUrlObject(files)
  window.open(res.data1)
}
