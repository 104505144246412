import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { DownOutlined, CloseOutlined } from '@ant-design/icons'
import { Drawer, Dropdown, Menu, Modal } from 'antd'
import {
	creditAction,
	loadingAction,
	setRouteHistory,
	profileAction,
	permissionsAction,
	notiOverdueAction,
	popupAction,
} from '../actions'
import {
	Box,
	Container,
	Header,
	Image,
	Label,
	Footer,
	Link,
	Hamburger,
	MenuList,
	Loading,
	Button,
	Modal as ModalCustom,
} from '../components'
import {
	convertStrToFormat,
	isValidResponse,
	LIST,
	processLogout,
	redirect,
	ROUTE_PATH,
	setUserAuth,
	convertMonth,
  openFile
} from '../helpers'
import {
	userController,
	systemController,
	invoiceController,
} from '../apiServices'
import moment from 'moment'

export const Layout = ({ children }) => {
	const dispatch = useDispatch()
	const popupList = useSelector((state) => state.popupReducer)
	const modalOverdue = useRef(null)
	const isLoading = useSelector((state) => state.loadingReducer)
	const userAuth = useSelector((state) => state.authenReducer)
	const { creditBalance } = useSelector((state) => state.creditReducer)
	const premistions = useSelector((state) => state.premissionsReducer)
	const notiOverdue = useSelector((state) => state.notiOverdueReducer)
	const history = useHistory()
	const location = useLocation()
	const [drawerVisible, setDrawerVisible] = useState(false)
	const [profile, setProfile] = useState({})
	const [textOverdue, setTextOverdue] = useState('')
	const [dataGenInv, setDataGenInv] = useState({})

	const fetchData = useCallback(async () => {
		if (userAuth.status) {
			const { access_token } = userAuth
			const API = userController({ access_token })
			const res = await API.getProfile()
			if (isValidResponse(res)) {
				const dataResult = res.result
				const { credit_vif_cur, credit_vif_total, credit_vif_use } = dataResult

				fetchOverdueCredit(dataResult)
				setProfile(dataResult)
				setUserAuth({ access_token, dataResult })
				dispatch(loadingAction(false))
				dispatch(
					creditAction({
						creditBalance: credit_vif_cur,
						creditTotal: credit_vif_total,
						creditUse: credit_vif_use,
					})
				)
				dispatch(profileAction(dataResult))
			} else {
				await processLogout()
				setProfile({})
				window.location.href =
					'https://www.fininsurance.co.th/finlock?action=logout'
			}
		}
	}, [dispatch, userAuth])

	const fetchPremission = useCallback(async () => {
		if (userAuth.status && premistions.sha_code) {
			const API = userController()
			const res = await API.checkPremissionteam(premistions.sha_code)
			if (isValidResponse(res)) {
				const paramsPremiss = res.result
				dispatch(permissionsAction(paramsPremiss))
			}
		}
	}, [dispatch, userAuth, premistions.sha_code])

	const fetchOverDue = useCallback(async () => {
		if (
			(location.pathname === ROUTE_PATH.HOMEPAGE.LINK ||
				location.pathname === ROUTE_PATH.COMPULSORY_MOTOR.LINK ||
				location.pathname === ROUTE_PATH.CAR_INSURANCE.LINK ||
				location.pathname === ROUTE_PATH.VEHICLE_SELECTION.LINK ||
				location.pathname === ROUTE_PATH.WORKWAITING.LINK) &&
			profile.vif_type === '2'
		) {
			const API = systemController()
			const res = await API.checkNotiOverdue()
			if (isValidResponse(res)) {
				const dataResult = res.result
				const { overdue } = dataResult[0]
				const dateNow = moment().format('YYYY-MM-DD HH:mm:ss')
				const dateShowOverDue = moment(notiOverdue.date_show || dateNow).format(
					'YYYY-MM-DD HH:mm:ss'
				)
				if (overdue && dateShowOverDue <= dateNow) {
					dispatch(
						notiOverdueAction({
							date_show: moment().add(2, 'hours'),
						})
					)
					Modal.warning({
						title: `ท่านมียอดค้างชำระเกินดิว !!`,
						content: `กรุณาชำระยอดคงค้าง จำนวน ${convertStrToFormat(
							overdue,
							'money'
						)} บาท ให้เรียบร้อย`,
					})
				}
			}
		}
	}, [dispatch, location, profile.vif_type, notiOverdue.date_show])

	const checkCredit = useCallback(async () => {
		if (location.pathname === '/') {
			redirect(ROUTE_PATH.HOMEPAGE.LINK)
		}

		if (profile.vif_type && profile.vif_type !== '1' && creditBalance <= 0) {
			if (
				location.pathname === ROUTE_PATH.COMPULSORY_MOTOR.LINK ||
				location.pathname === ROUTE_PATH.CAR_INSURANCE.LINK ||
				location.pathname === ROUTE_PATH.VEHICLE_SELECTION.LINK ||
				location.pathname === ROUTE_PATH.WORKWAITING.LINK
			) {
				redirect(ROUTE_PATH.HOMEPAGE.LINK)
				Modal.error({
					title: 'ไม่สามารถใช้งานได้',
					content: 'กรุณาชำระเงิน เนื่องจากวงเงินคงเหลือไม่เพียงพอ',
					onOk: () => redirect(`${ROUTE_PATH.REPORT.LINK}/invoice`),
					okText: 'ชำระเครดิต',
				})
			}
		} else if (
			profile.vif_type &&
			profile.vif_type === '1' &&
			creditBalance < 0
		) {
			if (
				location.pathname === ROUTE_PATH.COMPULSORY_MOTOR.LINK ||
				location.pathname === ROUTE_PATH.CAR_INSURANCE.LINK ||
				location.pathname === ROUTE_PATH.VEHICLE_SELECTION.LINK ||
				location.pathname === ROUTE_PATH.WORKWAITING.LINK
			) {
				redirect(ROUTE_PATH.HOMEPAGE.LINK)
				Modal.error({
					title: 'ไม่สามารถใช้งานได้',
					content: 'กรุณาเติมเงิน เนื่องจากวงเงินคงเหลือไม่เพียงพอ',
					onOk: () => redirect(`${ROUTE_PATH.DEBIT.LINK}/plusmoney`),
					okText: 'เติมเครดิต',
				})
			}
		}

		if (profile.is_payment_vif === 'no') {
			if (
				location.pathname === ROUTE_PATH.COMPULSORY_MOTOR.LINK ||
				location.pathname === ROUTE_PATH.CAR_INSURANCE.LINK ||
				location.pathname === ROUTE_PATH.VEHICLE_SELECTION.LINK ||
				location.pathname === ROUTE_PATH.WORKWAITING.LINK
			) {
				redirect(ROUTE_PATH.HOMEPAGE.LINK)
				Modal.error({
					title: 'เนื่องจากเกินเวลากำหนดชำระ ระบบจึงทำการล็อกรหัสผู้ใช้งาน',
					content: 'กรุณาติดต่อเจ้าหน้าที่เพื่อทำการปลดล็อกระบบ',
				})
			}
		}
	}, [])

	const fetchOverdueCredit = useCallback(async (dataRes) => {
		const { is_payment_vif: isPayVif, cuscode } = dataRes
		const date = moment().format('YYYY-MM-DD')
		const month = moment(date).format('MM')
		const year = moment(date).format('YYYY')
		const monthOld = moment(date).add(-1, 'months').format('MM')
		const yearOld = moment(date).add(-1, 'months').format('YYYY')
		const dateLastMonthOld = moment(date)
			.add(-1, 'months')
			.subtract(0, 'months')
			.endOf('month')
			.format('DD')

		const textYear = +year + 543
		const textMonth = convertMonth(month)
		const textYearOld = +yearOld + 543
		const textMonthOld = convertMonth(monthOld)

		if (
			(popupList?.overdueCredit?.date === date && isPayVif !== 'no') ||
			cuscode === 'Admin-VIF'
		) {
			return
		}

		const API = systemController()
		const res = await API.getOutstandingBalanceHome({ date })

		if (isValidResponse(res)) {
			const { data, periodDate } = res.result
			let text = ''

			if (data.length === 0) return

			if (isPayVif === 'no') {
				text = `ข้อความเลยกำหนดดิวชำระตามรอบ เนื่องจากท่านยังมียอดค้างอยู่ในระบบ\nกรุณาชำระยอดคงค้าง เพื่อยืนยันการตัดยอดของท่าน`
			} else if (
				popupList?.overdueCredit?.date !== date &&
				periodDate === '1'
			) {
				text = `ข้อความก่อนครบกำหนด 5 วันหลังวางบิลวันที่ 16 ของเดือน\nรอบแจ้งงานวันที่ 1-15 ${textMonth} พ.ศ. ${textYear}\nครบกำหนดชำระภายในวันที่ 20 ${textMonth} พ.ศ. ${textYear} \nสามารถชำระเข้าระบบเพื่อการยืนยันข้อมูลการชำระของท่าน`
			} else if (
				popupList?.overdueCredit?.date !== date &&
				periodDate === '2'
			) {
				text = `ข้อความก่อนครบกำหนด 5 วันหลังวางบิลวันที่ 1 ของเดือน\nรอบแจ้งงานวันที่ 16-${dateLastMonthOld} ${textMonthOld} พ.ศ. ${textYearOld}\nครบกำหนดชำระภายในวันที่ 05 ${textMonth} พ.ศ. ${textYear}\nสามารถชำระเข้าระบบ เพื่อการยืนยันข้อมูลการชำระของท่าน`
			}
			setTextOverdue(text)
			modalOverdue.current.open()
			setDataGenInv(res.result)
		}
	}, [])

	useEffect(() => {
		fetchData()
		fetchPremission()
		fetchOverDue()
		dispatch(setRouteHistory(history))
		checkCredit()
	}, [
		dispatch,
		history,
		location,
		fetchData,
		fetchPremission,
		fetchOverDue,
		checkCredit,
		creditBalance,
		profile.vif_type,
		profile.is_payment_vif,
	])

	const handleShowDrawer = () => {
		setDrawerVisible(true)
	}

	const handleCloseDrawer = () => {
		setDrawerVisible(false)
	}

	const closeOverdueCd = () => {
		const updatedPopup = {
			numHomepage: 1,
			date: moment().format('YYYY-MM-DD'),
		}
		dispatch(popupAction({ ...popupList, overdueCredit: { ...updatedPopup } }))
	}

	const handleClickExport = async () => {
		dispatch(loadingAction(true))
		const { endDate, data, periodDate } = dataGenInv
		const params = {
			end_date: endDate,
			type: 'all',
			periodDate,
			selectedRow: data.map((e) => e.quo_num),
		}
		const API = invoiceController()
		const res = await API.genInvoiceVif(params)
		if (isValidResponse(res)) {
			const pdf = res.result.url
      openFile({data1: pdf})
			// window.open(pdf)
			dispatch(loadingAction(false))
		}
	}

	const logout = async () => {
		await processLogout()
		setProfile({})
		const { device_id, dataResult } = userAuth
		const body = {
			cuscode: dataResult?.cuscode,
			device_id,
		}
		if (dataResult?.cuscode) {
			const API = userController()
			await API.logout(body)
		}
		window.location.href =
			'https://www.fininsurance.co.th/finlock?action=logout'
	}

	const menu = (
		<Menu>
			<Menu.Item key='1'>
				<Label onClick={logout}>ออกจากระบบ</Label>
			</Menu.Item>
		</Menu>
	)

	return (
		<>
			{isLoading && <Loading />}
			<Header>
				<Container className='header-wrapper'>
					<Box className='left-menu'>
						<div>
							<Hamburger onClick={handleShowDrawer} />
							<Drawer
								title={
									<Label className='menu-title'>
										<Image
											//fin-red src='https://www.fininsurance.co.th/wp-content/uploads/2017/01/logo-fin-600x242.png'
											src='https://www.fininsurance.co.th/wp-content/uploads/2017/01/logo-fin-thai.png'
											alt='fin'
											className='logo-header'
										/>
									</Label>
								}
								placement='left'
								closable={false}
								onClose={handleCloseDrawer}
								key='left'
								visible={drawerVisible}
								bodyStyle={{
									padding: 0,
								}}
								width='250'
								headerStyle={{
									background: '#f14d51',
								}}
							>
								<MenuList
									dataList={LIST.MENU(
										profile.vif_type,
										profile.admin_vif,
										profile.role,
										premistions.status_permissions,
										premistions.cuscode
									)}
								/>
							</Drawer>
						</div>
						<Link to={ROUTE_PATH.HOMEPAGE.LINK}>
							<Image
								src='https://www.fininsurance.co.th/wp-content/uploads/2017/01/logo-fin-thai.png'
								alt='ฟินประกันรถ'
								className='logo-header'
							/>
						</Link>
					</Box>
					<Box className='profile-header'>
						<Dropdown overlay={menu} trigger={['click']}>
							<Box>
								<Label className='profile-header-text'>
									ID : {premistions.cuscode} <DownOutlined />
								</Label>
							</Box>
						</Dropdown>
						<Label className='name'>{`ชื่อร้าน: ${premistions.name}`}</Label>
						<Label className='credit'>
							{`วงเงินคงเหลือ: ${
								profile.credit_vif_cur
									? convertStrToFormat(creditBalance, 'money_digit')
									: ''
							} บาท`}{' '}
							{profile.vif_type !== '1' ? '(เครดิต)' : '(เติมเงิน)'}
						</Label>
					</Box>
				</Container>
			</Header>
			{children}
			<Footer>
				<Container className='footer-wrapper'>
					<Box className='social-media-logo-footer-wrapper'>
						<a href='tel:0914939999'>
							<Image
								src='https://fininsurance.co.th/wp-content/uploads/2017/01/tel-test.png'
								alt='FininsuranceTel'
								className='social-media-logo-footer'
							/>
						</a>
						<a href='https://www.facebook.com/fininsurance/'>
							<Image
								src='https://fininsurance.co.th/wp-content/uploads/2017/01/facebooktest.png'
								alt='FininsuranceFacebook'
								className='social-media-logo-footer'
							/>
						</a>
						<a href='https://line.me/R/ti/p/@fininsurance'>
							<Image
								src='https://fininsurance.co.th/wp-content/uploads/2017/01/linetest.png'
								alt='FininsuranceLine'
								className='social-media-logo-footer'
							/>
						</a>
					</Box>
					<div>
						<Label color='white'>Fin Insurance Broker Co,. Ltd.</Label>
					</div>
				</Container>
				<ModalCustom
					modalHead='modal-header-red'
					headerText={
						profile.is_payment_vif === 'no'
							? 'ติดตามการวางบิล (เลยครบกำหนด)'
							: 'ติดตามการวางบิล (ก่อนครบกำหนดวางบิล)'
					}
					ref={modalOverdue}
					iconsClose={
						profile.is_payment_vif === 'no' ? (
							''
						) : (
							<CloseOutlined onClick={closeOverdueCd} />
						)
					}
				>
					<Box className='modal-overdue-home'>
						<Box>{textOverdue}</Box>
						{premistions.status_permissions !== 'notview' && (
							<Button
								bgColor='#f14d51'
								style={{
									width: '150px',
									height: '50px',
									border: 0,
									borderRadius: '10px',
									color: '#FFFFFF',
								}}
								onClick={handleClickExport}
							>
								ชำระเงิน
							</Button>
						)}
					</Box>
				</ModalCustom>
			</Footer>
		</>
	)
}
