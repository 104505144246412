import { LIST } from '../../helpers'

export const getYearPrb = (companyCompulsory, brand) => {
	switch (companyCompulsory) {
		case 'ชับบ์สามัคคีประกันภัย':
			return LIST.CAR_YEAR_CHUBB(brand)
		default:
			return LIST.CAR_YEAR(brand)
	}
}

export const getSeriesPrb = (companyCompulsory, brand, year) => {
	switch (companyCompulsory) {
		case 'แอกซ่าประกันภัย':
			return LIST.CAR_SERIES_DD(brand)
		case 'เจมาร์ทประกันภัย (เจพี)':
			return LIST.CAR_SERIES_Jaymart(brand)
		case 'เออร์โกประกันภัย':
		case 'ไทยศรีประกันภัย':
			return LIST.CAR_SERIES_THAISRI(brand)
		case 'อินทรประกันภัย':
		case 'อินทรประกันภัย(สาขาสีลม)':
			return LIST.CAR_SERIES_INTRA_DJ(brand)
		case 'ทิพยประกันภัย':
			return LIST.CAR_SERIES_DHIPAYA(brand)
		case 'ไทยเศรษฐกิจประกันภัย':
			return LIST.CAR_SERIES_THAISETAKIJ(brand)
		case 'เคดับบลิวไอประกันภัย':
			return LIST.CAR_SERIES_KWI(brand)
		case 'ชับบ์สามัคคีประกันภัย':
			return LIST.CAR_SERIES_CHUBB(brand, year)
		case 'บริษัทกลาง':
			return LIST.CAR_SERIES_RVP(brand)
		case 'ฟอลคอนประกันภัย':
			return LIST.CAR_SERIES_FCI(brand)
		default:
			return LIST.CAR_SERIES(brand, year)
	}
}

export const getSubSeriesPrb = (companyCompulsory, brand, year, series) => {
	switch (companyCompulsory) {
		case 'แอกซ่าประกันภัย':
			return LIST.CAR_SUB_SERIES_DD(brand, series)
		// case 'ไทยศรีประกันภัย':
		// 	return LIST.CAR_SUB_SERIES_THAISRI(brand, series, year)
		case 'ทิพยประกันภัย':
			return LIST.CAR_SUB_SERIES_DHIPAYA(series)
		default:
			return LIST.CAR_SUB_SERIES(brand, year, series)
	}
}

export const getYearIns = (companyInsurance, companyCompulsory, brand) => {
	if (companyInsurance === 'ไทยศรีประกันภัย') {
		return LIST.CAR_YEAR_THAISRI_INS(brand)
	} else if (companyInsurance === 'ชับบ์สามัคคีประกันภัย') {
		return LIST.CAR_YEAR_CHUBB(brand)
	} else if (companyInsurance === 'แอกซ่าประกันภัย') {
		return LIST.CAR_YEAR_AXA_INS(brand)
	} else if (companyInsurance === 'ฟอลคอนประกันภัย') {
		return LIST.CAR_YEAR_FCI_INS(brand)
	}
	// else if (select?.companyInsurance === 'วิริยะประกันภัย') {
	// 	return LIST.CAR_YEAR_VIRIYA_INS(value).then((e) => setYearList(e))
	// }
	else {
		return LIST.CAR_YEAR(brand)
	}
}

export const getSeriesIns = (
	companyInsurance,
	companyCompulsory,
	brand,
	year
) => {
	if (companyInsurance === 'ไทยศรีประกันภัย') {
		return LIST.CAR_SERIES_THAISRI_INS(brand, year)
	} else if (
		companyInsurance === 'ทิพยประกันภัย' ||
		companyCompulsory === 'ทิพยประกันภัย'
	) {
		return LIST.CAR_SERIES_DHIPAYA(brand)
	} else if (
		companyInsurance === 'ชับบ์สามัคคีประกันภัย' ||
		companyCompulsory === 'ชับบ์สามัคคีประกันภัย'
	) {
		return LIST.CAR_SERIES_CHUBB(brand, year)
	} else if (companyInsurance === 'วิริยะประกันภัย') {
		return LIST.CAR_SERIES_VIRIYA_INS(brand)
	} else if (companyInsurance === 'แอกซ่าประกันภัย') {
		return LIST.CAR_SERIES_AXA_INS(brand, year)
	} else if (companyCompulsory === 'เจมาร์ทประกันภัย (เจพี)') {
		return LIST.CAR_SERIES_Jaymart(brand, year)
	} else if (
		['อินทรประกันภัย', 'อินทรประกันภัย(สาขาสีลม)'].includes(companyCompulsory)
	) {
		return LIST.CAR_SERIES_INTRA_DJ(brand)
	} else if (companyCompulsory === 'ไทยเศรษฐกิจประกันภัย') {
		return LIST.CAR_SERIES_THAISETAKIJ(brand)
	} else if (companyInsurance === 'เคดับบลิวไอประกันภัย') {
		return LIST.CAR_SERIES_KWI(brand)
	} else if (companyInsurance === 'ฟอลคอนประกันภัย') {
		return LIST.CAR_SERIES_FCI_INS(brand, year)
	} else {
		return LIST.CAR_SERIES(brand, year)
	}
}

export const getSubSeriesIns = (
	companyInsurance,
	companyCompulsory,
	brand,
	year,
	series
) => {
	if (companyInsurance === 'ไทยศรีประกันภัย') {
		return LIST.CAR_SUB_SERIES_THAISRI_INS(brand, series, year)
	} else if (
		companyInsurance === 'ทิพยประกันภัย' ||
		companyCompulsory === 'ทิพยประกันภัย'
	) {
		return LIST.CAR_SUB_SERIES_DHIPAYA(series)
	} else if (companyInsurance === 'วิริยะประกันภัย') {
		return LIST.CAR_SUB_SERIES_VIRIYA_INS(brand, series)
	} else if (companyInsurance === 'แอกซ่าประกันภัย') {
		return LIST.CAR_SUB_SERIES_AXA_INS(brand, year, series)
	} else if (companyInsurance === 'ฟอลคอนประกันภัย') {
		return LIST.CAR_SUB_SERIES_FCI_INS(brand, year, series)
	} else {
		return LIST.CAR_SUB_SERIES(brand, year, series)
	}
}
